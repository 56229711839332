import React from "react"
import { navigate } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

const Redirect = () => {
    const intl = useIntl()
    React.useEffect(() => {
        const { pathname } = window.location
        if (pathname.startsWith(`/${intl.locale}/`)) {
            // If the path already has a language prefix, don't add another
            navigate(pathname)
        } else {
            navigate(`/${intl.locale}${pathname}`, { replace: true })
        }
    }, [intl.locale])

    return null
}

export default Redirect